import { useGetSettings } from "hooks/useGetSettings";
import { isTheShopOpen } from "utils/checkIsShopOpen";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TEMP_CLOSED } from "config";

interface OpeningTimesActionProps {
  onError?: () => void;
  onSuccess?: () => void;
  showAdditionalToasts?: boolean;
}

export const useOpeningTimesAction = () => {
  const { fetchSettings } = useGetSettings();

  const checkIsOpen = async ({
    onError,
    onSuccess,
    showAdditionalToasts = false,
  }: OpeningTimesActionProps) => {

    if(TEMP_CLOSED) {
      toast.info(
        "Nasz foodtruck jest tymczasowo nieczynny. Po więcej informacji zapraszamy na nasz profil na Facebooku."
      );
      return;
    }

    await fetchSettings().then((response) => {
      const isOpen = isTheShopOpen()
      if(!isOpen) {
        onError?.();
        toast.error(
          "Nie przyjmujemy teraz zamówień. Prosimy spróbować ponownie w godzinach otwarcia."
        );
        return;
      }
      if (!response?.delivery && !response?.collection) {
        onError?.();
        toast.error(
          "Nie przyjmujemy teraz zamówień. Po więcej informacji prosimy odwiedzić nasz profil na Facebooku."
        );
        return;
      }
      if (!response?.delivery) {
        if (showAdditionalToasts) {
          toast.info(
            "Realizujemy tylko zamówienia na odbiór. Dostawy są tymczasowo niedostępne."
          );
        }
        onSuccess?.()
        return;
      }
      if (isOpen && (response?.delivery || response?.collection)) {
        onSuccess?.();
      } else {
        onError?.();
        toast.error(
          "Nie przyjmujemy teraz zamówień. Prosimy spróbować ponownie w godzinach otwarcia."
        );
      }
    });
  };

  return { checkIsOpen };
};
