import { Typography } from "components/Typography/Typography"
import { InfoCard } from "./InfoCard"
import { CiMail ,CiClock1 , CiLocationOn } from "react-icons/ci";
import { PiPhoneThin } from "react-icons/pi";
import classes from './OurInfo.module.scss'
import { OpeningTimes } from "./OpeningTimes/OpeningTimes";
import { TEMP_CLOSED } from "config";

export const OurInfo = () => {
    return (
        <div className={classes.OurInfo}>
            <InfoCard
                title="LOKALIZACJA"
                desc={
                    <div>
                        <Typography format="h3">Rożnowska 7</Typography>
                        <Typography format="h3">32-020 Wieliczka</Typography>
                    </div>
                }
                Icon={CiLocationOn}
                testID="contact-card-location"
            />
             <InfoCard
                title="NUMER TELEFONU"
                desc={
                    <div>
                        <Typography format="h3">+48 664 138 905</Typography>
                    </div>
                }
                Icon={PiPhoneThin}
                testID="contact-card-phone"
            />
             <InfoCard
                title="GODZINY OTWARCIA"
                desc={
                    <div>
                        {TEMP_CLOSED ? (
                            <Typography format="h3">Tymczasowo zamknięte</Typography>
                        ) : (
                            <OpeningTimes/>
                        )}
                    </div>
                }
                Icon={CiClock1}
                testID="contact-card-opening-hours"
            />
             <InfoCard
                title="EMAIL"
                desc={
                    <div>
                        <Typography format="h3">pl@captain-potato.com</Typography>
                    </div>
                }
                Icon={CiMail}
                testID="contact-card-email"
            />
        </div>
    )
}